import { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Register from "./Register";
import Cart from "./Cart";
import Wishlist from "./Wishlist";
import Home from "./Home";
import Minorcategory from "./MinorCategoryProducts";
import Productdetails from "./Productdetails";
import Header from "./Header";
import Footer from "./Footer";

import "./App.css";
import axios from "axios";
import AboutUs from "./AboutUs";
import ScrollToTop from "./ScrollToTop";
import { baseUrl } from "./baseUrl";
import Subcategory from "./SubcategoryProducts";
import Order_checkout from "./Order_checkout";
import Category from "./CategoryProducts";
import Contactus from "./Contactus";
import RegisterOTP from "./RegisterOTP";
import LoginOTP from "./LoginOTP";
import Profile from "./Profile";
import SeachedProducts from "./SearchedProduct";
import Place from "./PlaceOrder";
import Fail from "./Fail";
import MyOrders from "./MyOrder";
import OrderDetails from "./OrderDetail";
import Login from "./login";
import Signup from "./Signup";
import Term from "./Term";
import Privacy from "./Privacy";

function App() {
  const [cartLength, setCL] = useState(0);
  const [wishLength, setWL] = useState(0);
  const [token, setToken] = useState("");
  const [phone, setPhone] = useState("");
  const [authentication, setAuthentication] = useState("");
  const [popUpMessage, setPUM] = useState("");
  // Setting phone, authentication and token from local storage
  const checkAndSetCredentials = () => {
    // Getting phone, authentication and token from local storage
    const user_token = localStorage.getItem("user_token");
    const user_phone = localStorage.getItem("supremetech_phone");
    const user_authentication = localStorage.getItem(
      "supremetech_authentication"
    );

    // If login/Registered
    if (user_phone && user_authentication) {
      // alert("phone and authentication are all set");
      setToken(user_token);
      setPhone(user_phone);
      setAuthentication(user_authentication);
    }

    // If no token in local storage
    if (!user_token) {
      fetch(baseUrl + "Token/get_token").then((resp) => {
        resp.json().then((result) => {
          localStorage.setItem("user_token", result.token);
          setToken(result.token);
          console.log("token", token);
        });
      });
    }
    //  if token in local storage
    else {
      console.log("user_tok", user_token);
      console.log("token", token);
      // this.setState({ token: user_token });
      setToken(user_token);
    }
  };
  useEffect(() => {
    checkAndSetCredentials();
  }, [token, authentication, phone]);
  const logoutUser = () => {
    setPhone("");
    setAuthentication("");
    localStorage.setItem("supremetech_phone", "");
    localStorage.setItem("supremetech_authentication", "");
    localStorage.setItem("supremetech_name", "");
    localStorage.setItem("supremetech_address", "");
    localStorage.setItem("user_token", "");
    window.location.reload();
    return <Redirect to="/" />;
  };
  // Change the popup text
  const changePopupText = (text) => {
    setPUM(text);
    setTimeout(() => {
      setPUM("");
    }, 3000);
  };
  const history = useHistory();

  //---------------------------Add to cart-------------------------------------------//
  // Adding to cart
  const addingToCart = async (product_id, quantity) => {
    // alert(typeof(quantity))
    if (
      !(
        localStorage.getItem("supremetech_authentication") === "" ||
        localStorage.getItem("supremetech_authentication") === null
      )
    ) {
      if (quantity === 0) {
        changePopupText("Quantity must be greater than 0.");
        return;
      }
      console.log(
        "product_id, quantity, type_id, phone, authentication",
        product_id,
        quantity
      );
      // alert("at 1", product_id, quantity, type_id, authentication);
      try {
        var postCartData = new FormData();
        postCartData.append("product_id", product_id);
        postCartData.append("quantity", quantity);
        postCartData.append("token_id", token);
        postCartData.append("authentication", authentication);
        postCartData.append("phone", phone);

        for (let [key, value] of postCartData) {
          console.log("Cart ka data", key, ":", value);
        }
        const response = await axios.post(
          baseUrl + "Apicontroller/add_to_cart",
          postCartData
        );
        console.log("hogaya na post cart", response);
        if (response.data.status === 201 || response.data.code === 201) {
          changePopupText(response.data.message);
          // setTimeout(()=> {
          //   setPUM("")
          // }, 3000)
          // alert(response.data.message);
        } else if (response.data.status === 200 || response.data.code === 200) {
          // alert(response.data.message);
          changePopupText("Success! Item Successfully added to your cart");
          let responseOfCDL = countCartLength();
          console.log("responseOfCDL", responseOfCDL);
        }
      } catch {
        console.log("Issue in add to cart");
      }
    } else {
      // alert("Register karo");
      history.push("/register");
    }
  };


  // counting cart length
  const countCartLength = async () => {
    try {
      var getCartData = new FormData();
      getCartData.append("token_id", token);
      getCartData.append("phone", phone);
      getCartData.append("authentication", authentication);
      for (var pair of getCartData.entries()) {
        console.log("getCartData is heres", pair[0] + ", " + pair[1]);
      }
      const response = await axios.post(
        baseUrl + "Apicontroller/cart_count",
        getCartData
      );

      console.log("Cart Length waala aagaya", response.data.data);

      // if cart is not empty
      if (response.data.data !== undefined) {
        setCL(response.data.data);
        setWL(response.data.wishlist_count);
      }
      // if cart is empty
      else {
        setCL(0);
        setWL(0);
      }
      console.log("Count cart in app", response.data);
      return response.data;
    } catch {
      console.log("Issue in count cart.");
    }
  };
  // Adding to wishlist
  const addingToWishlist = async (product_id, quantity) => {
    console.log(
      "product_id, quantity, type_id, email_id, password",
      product_id,
      quantity
    );
    // alert("at 1", product_id, quantity, type_id, password);
    try {
      var postWishlistData = new FormData();
      postWishlistData.append("product_id", product_id);
      postWishlistData.append("quantity", quantity);
      postWishlistData.append("token_id", token);
      postWishlistData.append("authentication", authentication);
      postWishlistData.append("phone", phone);
      for (let [key, value] of postWishlistData) {
        console.log("Wishlist ka data", key, ":", value);
      }

      const response = await axios.post(
        baseUrl + "Apicontroller/add_to_wishlist",
        postWishlistData
      );
      console.log("hogaya na post cart", response);
      if (response.data.status === 201 || response.data.code === 201) {
        changePopupText(response.data.message);
        // setTimeout(()=> {
        //   setPUM("")
        // }, 3000)
        // alert(response.data.message);
      } else if (response.data.status === 200 || response.data.code === 200) {
        // alert(response.data.message);
        changePopupText("Success! Item Successfully added to your wishlist");
        // setTimeout(()=> {
        //   setPUM("")
        // }, 3000)
        // let responseOfCDL = countCartLength();
        // console.log("responseOfCDL", responseOfCDL);
      }
    } catch {
      console.log("Issue in add to wishlist.");
    }
  };
  return (
    <div>
      <Header
        cartLength={cartLength}
        countCartLengthFunction={countCartLength}
        popUpMessage={popUpMessage}
        phone={phone}
        authentication={authentication}
        logoutUser={logoutUser}
        changePopupText={changePopupText}
      />
      <ScrollToTop />
      <div className="mt-5 pt-5">
        <Switch>
          <Route exact path="/fail" component={Fail} />
          <Route exact path="/myorders" component={()=><MyOrders changePopupText={changePopupText} />} />
          <Route path="/order-details" component={OrderDetails} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup"
           render={() => (
            <Signup
              changePopupText={changePopupText} history={history}
            />
          )}  />
          <Route exact path="/contactus" component={Contactus} />
          <Route exact path="/term" component={Term} />
          <Route
            exact
            path="/wishlist"
            render={() => (
              <Wishlist
                changePopupText={changePopupText}
                addingToCart={addingToCart}
              />
            )}
          />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/aboutus" component={AboutUs} />
          <Route path="/search-result"
           render={() => (
            <SeachedProducts
            addingToCart={addingToCart}
            />
          )}
           />
          <Route
            exact
            path="/register-otp"
            render={() => (
              <RegisterOTP
                checkAndSetCredentials={checkAndSetCredentials}
                changePopupText={changePopupText}
              />
            )}
          />
          <Route exact path="/place" 
            render={() => (
              <Place
              countCartLength={countCartLength}
              />
            )}
            />
          <Route
            exact
            path="/login-otp"
            render={() => (
              <LoginOTP
                checkAndSetCredentials={checkAndSetCredentials}
                changePopupText={changePopupText}
              />
            )}
          />
          <Route
            path="/minorcategory"
            render={() => <Minorcategory addingToCart={addingToCart} />}
          />
          <Route
            path="/subcategory"
            render={() => <Subcategory addingToCart={addingToCart} />}
          />
          <Route
            path="/category"
            render={() => <Category addingToCart={addingToCart} />}
          />
          <Route
            path="/product-details"
            render={() => (
              <Productdetails
                addingToCart={addingToCart}
                addingToWishlist={addingToWishlist}
                changePopupText={changePopupText}
              />
            )}
          />
          <Route
            exact
            path="/basket"
            render={() => (
              <Cart
                cartLength={cartLength}
                countCartLengthFunction={countCartLength}
                changePopupText={changePopupText}
              />
            )}
          />
          {/* <Route path="/" component={Home} /> */}
          <Route
            exact
            path="/"
            render={() => <Home addingToCartFunction={addingToCart} />}
          />
            <Route exact path="/Order_checkout" render={() =>(<Order_checkout  changePopupText={changePopupText} history={history}/>)}/>
        </Switch>
      </div>
      <Footer changePopupText={changePopupText} authentication={authentication} />
    </div>
  );
  
}

export default App;
// >>>>>>> before discard
