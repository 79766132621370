import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import ProductCard from "./ProductCard";
export default function ProductSlider(props) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  return (
    <>
      {console.log("Props in product sliders", props)}
      {/* <div className="container"> */}
      <div className="row justify-content-center">
        <div className="col-md-12 justify-content-center">
          {props.isSlider?props.isBrand ? (
            <Carousel
              autoPlay={true}
              infinite={true}
              autoPlaySpeed={5000}
              centerMode={false}
              partialVisbile={false}
              className="mt-1"
              responsive={responsive}
            >
              {props
                ? props.products.map((elem, i) => {
                    console.log("most popular brand sliders", props);
                    return (
                      <>
                        <div class="col-lg-12 col-md-12 col-12 mb-4 p-2 text-center">
                          {/* <div className="card"> */}
                          <div className="car-img">
                            <img src={elem.image} style={{height: "5rem",width:"10rem"}}/>
                          </div>
                          {/* <div className="card-body"> */}
                          <p className="title"><b>{elem.name}</b></p>
                          <p>{elem.message}</p>
                          {/* </div> */}
                        </div>
                      </>
                    );
                  })
                : null}
            </Carousel>
          ) : (
            <Carousel
              autoPlay={true}
              infinite={true}
              autoPlaySpeed={5000}
              centerMode={false}
              partialVisbile={false}
              className="mt-1"
              responsive={responsive}
            >
              {props
                ? props.products.map((elem, i) => {
                    console.log("products sliders", props);
                    return (
                      <>
                        <ProductCard
                          product_id={elem.product_id}
                          productimage={elem.productimage}
                          productname={elem.productname}
                          mrp={elem.mrp}
                          price={elem.price}
                          t2_price={elem.t2_price}
                          t2_min={elem.t2_min}
                          stock={elem.stock}
                          addingToCartFunction={props.addingToCartFunction}
                          isSlider="true"
                        />
                      </>
                    );
                  })
                : null}
            </Carousel>
          ):
          (
            props
            ? props.products.map((elem, i) => {
                console.log("not slider products:", props);
                return (
                  <>
                    <div class="col-lg-3 col-md-3 col-6 mb-4 p-2">
                      {/* <div className="card"> */}
                      <div className="car-img">
                        <img src={elem.image} style={{height: "15rem"}} />
                      </div>
                      {/* <div className="card-body"> */}
                      <p className="title">{elem.name}</p>
                      <p>{elem.message}</p>
                      {/* </div> */}
                    </div>
                  </>
                );
              })
            : null)}
        </div>
      </div>
    </>
  );
}
