import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
export default function ProductCard(props) {
  console.log('this is product card props', props.stock)
  const [authentication, setAuthentication] = useState("");
  const [type, setType] = useState("");
  useEffect(() => checkAndSetCredentials(), []);
  const user_type = localStorage.getItem(
    "type"
  );
  const checkAndSetCredentials = () => {
    // Getting phone, authentication and token from local storage
    const user_phone = localStorage.getItem("supremetech_phone");
    const user_authentication = localStorage.getItem(
      "supremetech_authentication"
    );
    const user_type = localStorage.getItem(
      "type"
    );
    // If login/Registered
    if (user_phone && user_authentication) {
      setAuthentication(user_authentication);
      setType(user_type);
    }
  }
  // console.log('this is product card props.mrp', props.mrp)
  if (props.isSlider === "true") {
    return (
      <>
        <div key={props.product_id} class="p-2 col-md-12 col-12 col-sm-12">
          <div className="bor_ra text-left">
            <Link
              to={{
                pathname: `/product-details/${props.product_id}`,
                state: props.product_id,
              }}
            >
              <div style={{ position: 'relative' }}>
                {props.stock === 0 ?
                  <img
                    className="product-card-img"
                    src={props.productimage}
                    alt={props.productname}
                    style={{ height: "15rem", opacity: 0.5 }}
                  />
                  :
                  <img
                    className="product-card-img"
                    src={props.productimage}
                    alt={props.productname}
                    style={{ height: "15rem" }}
                  />
                }
                {props.stock === 0 ?
                  <div style={{
                    position: 'absolute',
                    color: 'white',
                    textAlign: 'center',
                    top: '50%',
                    right: '30%',
                    left: '30%',
                    background: "#d7314a",
                    // width:"100%"
                  }}>
                    <span>Out of Stock</span>
                  </div>
                  : null}
              </div>
              <p className="pro_name" style={{ minHeight: "48px" }}>
                {props.productname}
                {/* <small>
                {props.productname.length > 23
                  ? props.productname.substring(0, 23) + "..."
                  : props.productname}
              </small> */}
              </p>
            </Link>
            {/* <div className="price_box_cut text-center">
          <del>
            <span>
              <i className="fa fa-inr"></i> {props.mrp}
            </span>
          </del>
        </div> */}
            <div className="price_box">
              {/* <p className="imMRP" style={{textDecoration: "line-through",color: "red"}}>
            Price:
            <i className="fa fa-inr"></i> {props.mrp}
            {productPrice}
          </p> */}
              {authentication !== "" ?
                type === 'T3' ?
                  <div className="myDiv">
                    <p>
                      Price :
                      <i className="fa fa-inr ml-2"></i>{props.price}
                      {/* {productPrice} */}
                    </p>
                  </div>
                  :
                  <div className="myDiv" style={{ textAlign: 'center' }}>
                    <span>
                      Dealer Price :
                      <i className="fa fa-inr ml-2"></i>{props.price}
                      {/* {productPrice} */}
                    </span>
                    <p>
                      Your Price :
                      <i className="fa fa-inr ml-2"></i>{props.t2_price}
                      {/* {productPrice} */}
                    </p>
                  </div>
                : null}
            </div>
            <div>
              <small>
                <button
                  className={"cart_btn"}
                  style={{ backgroundColor: '#2b2b2b' }}
                  onClick={() =>
                    props.addingToCartFunction(props.product_id,  user_type === 'T3' ? 1 :props.t2_min)
                  }
                >
                  ADD TO CART <i className="fa fa-shopping-bag ml-2"></i>
                </button>
              </small>
              {/* <AddToCart className={"cart_btn"} productDetail={props} styles={{}} /> */}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div key={props.product_id} class="col-lg-3 col-md-3 col-6 mb-4 p-2" style={{}}>
          <div className="bor_ra">
            <Link
              to={{
                pathname: `/product-details/${props.product_id}`,
                state: props.product_id,
              }}
            >
              {props.stock === 0 ?
                <img
                  className="product-card-img"
                  src={props.productimage}
                  alt={props.productname}
                  style={{ height: "15rem", opacity: 0.5 }}
                />
                :
                <img
                  className="product-card-img"
                  src={props.productimage}
                  alt={props.productname}
                  style={{ height: "15rem" }}
                />
              }
              {props.stock === 0 ?
                <div style={{
                  position: 'absolute',
                  color: 'white',
                  textAlign: 'center',
                  top: '30%',
                  right: '30%',
                  left: '30%',
                  background: "#d7314a",
                  // width:"100%"
                }}>
                  <span>Out of Stock</span>
                </div>
                : null}
              <p className="pro_name" style={{ minHeight: "48px" }}>
                {props.productname}
                {/* <small>
                {props.productname.length > 23
                  ? props.productname.substring(0, 23) + "..."
                  : props.productname}
              </small> */}
              </p>
            </Link>
            {/*<div className="price_box_cut text-center">
          <del>
             <span>M.R.P: </span>
            <span>
              <i className="fa fa-inr"></i> 
              {props.mrp}
            </span>
          </del>
        </div> */}
            <div className="price_box" style={{ textAlign: 'center' }}>
              {authentication !== "" ?
                type === 'T3' ?
                  <p>
                    {/* Price:  */}
                    <i className="fa fa-inr"></i> {props.price}
                    {/* {productPrice} */}
                  </p>
                  :
                  <>
                    <span>
                      Dealer Price :
                      <i className="fa fa-inr ml-2"></i>{props.price}
                      {/* {productPrice} */}
                    </span>
                    <p>
                      Your Price :
                      <i className="fa fa-inr ml-2"></i>{props.t2_price}
                      {/* {productPrice} */}
                    </p>
                  </>
                : null}
            </div>
            <div>
              <small>
                <button
                  className={"cart_btn"}
                  style={{ backgroundColor: '#2b2b2b' }}
                  onClick={() =>
                    props.addingToCartFunction(props.product_id,  user_type === 'T3' ? 1 :props.t2_min)
                  }
                >
                  ADD TO CART <i className="fa fa-shopping-bag ml-2"></i>
                </button>
              </small>
              {/* <AddToCart className={"cart_btn"} productDetail={props} styles={{}} /> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}