import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "./Slider";
import { Link ,Redirect} from "react-router-dom";
import ProductSlider from "./ProductSlider";
import { baseUrl } from "./baseUrl";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
function Home(props) {
  const [minorCategory, setMinorCategory] = useState([]);
  const [mostPopularBrands, setMostPopularBrands] = useState([]);
  const [MostPopularProducts, setMostPopularProducts] = useState([]);
  const [FeaturedProducts, setFeaturedProducts] = useState([]);
  const user_phone = localStorage.getItem("supremetech_phone");
  const user_authentication = localStorage.getItem(
    "supremetech_authentication"
  );
  // const [stocks, setStocks] = useState([]);
  const getMinorCategory = async () => {
    try {
      let response = await axios(
        baseUrl + "Apicontroller/show_minorcategory"
      );
      console.log("Minor category", response);
      if (response.data.status === 200) {
        setMinorCategory(response.data.data);
      }
    } catch {
      console.log("Issue in minor category.");
    }
  };
  const logoutUser = () => {
    localStorage.setItem("supremetech_phone", "");
    localStorage.setItem("supremetech_authentication", "");
    localStorage.setItem("supremetech_name", "");
    localStorage.setItem("supremetech_address", "");
    localStorage.setItem("user_token", "");
    localStorage.setItem("type", 'T3');
    window.location.reload();
    return <Redirect to="/" />;
  };
  const getMostPopularProducts = async () => {
    try {
      let response = await axios(
        baseUrl + "Apicontroller/most_popular_products/"+user_phone +'/'+user_authentication
      );
      console.log("Most popular products", response);
      const user_type = localStorage.getItem(
        "type"
      );
      if(response.data?.type!=user_type){
        logoutUser()
      }
      if (response.data.status === 200) {
        setMostPopularProducts(response.data.data);
      }
    } catch {
      console.log("Issue in most popular products.");
    }
  };
  const getFeaturedProducts = async () => {
    try {
      let response = await axios(
        baseUrl + "Apicontroller/featured_products/"+user_phone +'/'+user_authentication
      );
      console.log("featured products", response);
      if (response.data.status === 200) {
        setFeaturedProducts(response.data.data);
      }
    } catch {
      console.log("Issue in featured products.");
    }
  };
  const getMostPopularBrand = async () => {
    try {
      let response = await axios(
        baseUrl + "Apicontroller/brands_view"
      );
      console.log("Most popular brands", response);
      if (response.data.status === 200) {
        setMostPopularBrands(response.data.data);
      }
    } catch {
      console.log("Issue in most popular brands.");
    }
  };
  // const getStocks = async () => {
  //   try {
  //     let response = await axios(
  //       baseUrl + "Apicontroller/stock_get"
  //     );
  //     console.log("Stocks", response);
  //     if (response.data.status === 200) {
  //       setStocks(response.data.data);
  //     }
  //   } catch {
  //     console.log("Issue in most popular brands.");
  //   }
  // };
  useEffect(() => getslider(), []);
  useEffect(() => {
    getMinorCategory();
  }, []);
  useEffect(() => {
    getMostPopularBrand();
  }, []);
  // useEffect(() => {
  //   getStocks();
  // }, []);
  useEffect(() => {
    getMostPopularProducts();
  }, []);
  useEffect(() => {
    getFeaturedProducts();
  }, []);
  const [data, setData] = useState([]);
  const getslider = async () => {
    try {
      let response = await axios.get(baseUrl + "Apicontroller/get_slider");
      console.log("slider data:", response.data);
      if (response.data.status === 200) {
        setData(response.data.data);
      }
    } catch {
      console.log("Issue in getslider.");
    }
  };
  // const slider2 = (
  // );
  return (
    <div>
      {/* //================================ Slider ================================== */}
      <div className="slider-outer">
        <Carousel
          autoPlay={true}
          interval={3000}
          showThumbs={false}
          infiniteLoop={true}
          emulateTouch
          swipeable={false}
          showStatus={false}
        >
          {data.length > 0
            ? data.map((elem, i) => {
              const imageUrl = window.innerWidth >= 650 ? elem.web_image : elem.mob_image;
              return (
                <div>
                  <img src={imageUrl} />
                </div>
              )
            }) : null}
        </Carousel>
      </div>
      {/* <Slider /> */}
      {/* //================================ Category ================================== */}
      <section className="mt-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-center">
            </div>
          </div>
          <div className="row mt-4 cat_gory">
            {minorCategory
              ? minorCategory.sort(() => 0.5 - Math.random()).map((elem, i) => {
                if (i < 12) {
                  return (
                    <div key={elem.id} className="col-md-3 col-6">
                      <Link to={"/minorcategory/" + elem.id}>
                        <img src={elem.image} alt={elem.minorcategory} style={{boxShadow: "5px 4px 10px grey"}} />
                      </Link>
                      <h4 className="text-center">{elem.minorcategory}</h4>
                    </div>
                  );
                }
              })
              : null}
          </div>
        </div>
      </section>
      {/* //================================ MOST POPULAR PRODUCTS ================================== */}
      <section className="mt-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-center my-5">
              <h1 style={{marginBottom:"0px"}}>OUR MOST POPULAR PRODUCTS</h1>
              <img src="../img/bottom.png" style={{width:"14rem",marginTop:"-10px"}}></img>
            </div>
          </div>
          {MostPopularProducts ? (
            <ProductSlider
              products={MostPopularProducts}
              isSlider={true}
              isBrand={false}
              addingToCartFunction={props.addingToCartFunction}
              mostpopularbrands={[]}
            />
          ) : (
            ""
          )}
        </div>
      </section>
      {/* //================================ 4 Images ================================== */}
      {/* {stocks
        ? stocks.map((item, i) => (
          <>
            <section class="mt-5 pt-5">
              <div class="container">
                <div class={"row " + (i === 1 ? "flex-row-reverse" : "")}>
                  <div class="col-md-6 text-center p-lg-0 p-md-0 pb-3">
                    <img src={item.image} alt="" />
                  </div>
                  <div
                    class="col-md-6 text-white pr-lg-0 d-flex align-items-center"
                    style={{ background: "url(" + item.background_image + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
                  >
                    <div class="p-3">
                      <h4 class="mt-3">
                        <b>{item.name}</b>
                      </h4>
                      <p>{item.message}</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        ))
        : null} */}
      {/* single banner end */}
      {/* //================================ FEATURED PRODUCTS ================================== */}
      <section className="mt-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-center my-5">
              <h1 style={{marginBottom:"0px"}}>OUR FEATURED PRODUCTS</h1>
              <img src="../img/bottom.png" style={{width:"14rem",marginTop:"-10px"}}></img>
            </div>
          </div>
          {FeaturedProducts ? (
            <ProductSlider
              products={FeaturedProducts}
              isSlider={true}
              isBrand={false}
              addingToCartFunction={props.addingToCartFunction}
              mostpopularbrands={[]}
            />
          ) : (
            ""
          )}
        </div>
      </section>
      {/* //================================ Popular Brands ================================== */}
      <section className="mt-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-center my-5">
              <h1 style={{marginBottom:"0px"}}>OUR MOST POPULAR BRANDS</h1>
              <img src="../img/bottom.png" style={{width:"14rem",marginTop:"-10px"}}></img>
            </div>
          </div>
          <div className="row  cate_row overflow-hidden">
            <div className="col-md-12 ">
              <ProductSlider
                isSlider={true}
                products={mostPopularBrands}
                isBrand={true}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Home;
