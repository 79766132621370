import React, { useState } from "react";
function CartProduct(props) {
  const [AllCart, setVD] = useState([]);
  const [num, setNum] = useState(parseInt(props.AllCart.quantity));
  // console.log("cartProdComp props",props.AllCart.max)
  const inputNum = (event) => {
    const re = /^[0-9\b]+$/;
    if (props.user_type === 'T3') {
      if (event.target.value <= 0) {
        setNum(1)
      } else if (event.target.value === '' || re.test(event.target.value) === false) {
      } else {
        if (parseInt(event.target.value) < parseInt(props.AllCart.max)) {
          setNum(event.target.value);
        } else {
          setNum(props.AllCart.max)
        }
      }
    } else {
      if (event.target.value <= 0) {
        setNum(props.AllCart?.t2_min)
        props.UpdateCart(props.AllCart.product_id, props.AllCart?.t2_min)
      }else if(event.target.value <  parseInt(props.AllCart?.t2_min)){
          setNum(props.AllCart?.t2_min)
          props.UpdateCart(props.AllCart.product_id, props.AllCart?.t2_min)
      } else if (event.target.value === '' || re.test(event.target.value) === false) {
      } else {
        if (parseInt(event.target.value) < parseInt(props.AllCart.t2_max)) {
          setNum(event.target.value);
          props.UpdateCart(props.AllCart.product_id, event.target.value)
        } else {
          setNum(props.AllCart.t2_max)
          props.UpdateCart(props.AllCart.t2_max)
        }
      }
    }
  };
  const incNum = () => {
    if (props.user_type === 'T3') {
      if (num < props.AllCart.max) {
        setNum(num + 1);
        props.UpdateCart(props.AllCart.product_id, num + 1)
      } else {
        props.pop('Product max quantity limit reached');
      }
    } else {
      if (num < props.AllCart.t2_max) {
        setNum(num + 1);
        props.UpdateCart(props.AllCart.product_id, num + 1)
      } else {
        props.pop('Product max quantity limit reached');
      }
    }
  };
  const decNum = () => {
    if (props.user_type === 'T3') {
      if (num >= 2) {
        setNum(num - 1);
        props.UpdateCart(props.AllCart.product_id, num - 1)
      }
    } else {
      if(num <= parseInt(props.AllCart?.t2_min)){
        props.pop('Product min quantity limit reached');
        setNum(parseInt(props.AllCart?.t2_min));
      }
      else if (num >= 2) {
        setNum(num - 1);
        props.UpdateCart(props.AllCart.product_id, num - 1)
      } 
    }
  };
  return (
    <tr className="mb-4 mb-lg-0 text-center">
      <td className="pl-1">
        <img
          style={{ width: 25 }}
          src="img/cut.png"
          onClick={() => props.openModal(props.AllCart.product_id)}
        />
      </td>
      <td style={{ marginTop: "3rem" }}>
        <img className="pt-3 pb-3" src={props.AllCart.product_image} />
      </td>
      <td style={{ width: 228 }}>{props.AllCart.product_name}</td>
      <td>
        <span><i className="fa fa-inr"></i> </span>
        {props.user_type === 'T3' ?
          props.AllCart.price
          :
          props.AllCart.t2_price
        }
      </td>
      <td>
        <div className="cart-plus-minus justify-content-center">
          <span type="button" className="btn cart-minus-1 btn-danger text-white" onClick={decNum}>
            -
          </span>
          <input
            style={{ width: 56, height: '42px' }}
            type="text"
            name="qty"
            id="qty"
            maxLength={12}
            title="Qty"
            value={num}
            onChange={inputNum}
            className="input-text qty"
          />
          <span type="button" className="btn btn-danger cart-plus-1 text-white" onClick={incNum}>
            +
          </span>
        </div>
      </td>
      {/* <td>
        <button
          type="button"
          class="btn"
          style={{backgroundColor:'#2b2b2b',color:'white'}}
          onClick={() => props.UpdateCart(props.AllCart.product_id, num)}
        >
          Update
        </button>
      </td> */}
      <td>
        <p><i className="fa fa-inr"></i> {props.AllCart["total="]}</p>
      </td>
    </tr>
  );
}
export default CartProduct;
