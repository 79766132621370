import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "./baseUrl";
import ProductCard from "./ProductCard";
function Minorcategory(props) {
  const [allproduct, setallProduct] = useState([]);
  const [minorcategory, setMinorcategory] = useState([]);
  const [modalDis, setModalDis] = useState(false);
  const [pressed, setPressed] = React.useState(false);
  const mcategory = window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1
  );
  const user_phone = localStorage.getItem("supremetech_phone");
  const user_authentication = localStorage.getItem(
    "supremetech_authentication"
  );
  async function fetchAllProductData() {
    const formData = new FormData();
    formData.append("minorcategory_id", mcategory);
    formData.append("phone", user_phone);
    formData.append("authentication", user_authentication);
    try {
      const data = await axios.post(
        baseUrl + "Apicontroller/get_minorcategory_products",
        formData
      );
      console.log("minorcategory products all pd:", data);
      setallProduct(data.data.data);
      setMinorcategory(data.data.minorcategory_name);
    } catch {
      console.log("Issue in get product detail.");
    }
  }
  useEffect(() => {
    fetchAllProductData();
    setFN(initialState);
    setresoulation('')
    setIrdistance('')
    setCameratype('')
    setBodymaterial('')
    setVideochannel('')
    setPoeports('')
    setPoetype('')
    setSataports('')
    setLength('')
    setScreensize('')
    setLedtype('')
    setSize('')
    setLens('')
    setBrand('')
    setNightVision('')
    setAudioType('')
    window.onpopstate = e => {
      setPressed(true)
    };
  }, [mcategory]);
  const initialState = {
    brand: [],
    resoulation: [],
    irdistance: [],
    cameratype: [],
    bodymaterial: [],
    videochannel: [],
    poeports: [],
    poetype: [],
    sataports: [],
    lengths: [],
    screensize: [],
    ledtype: [],
    size: [],
    lens: [],
    night_vision: [],
    audio_type: [],
  };
  const [FilterName, setFN] = useState(initialState);
  const [emptyMessage, setEmptyMessage] = useState("");
  const [resoulation, setresoulation] = useState("");
  const [irdistance, setIrdistance] = useState("");
  const [cameratype, setCameratype] = useState("");
  const [bodymaterial, setBodymaterial] = useState("");
  const [videochannel, setVideochannel] = useState("");
  const [poeports, setPoeports] = useState("");
  const [poetype, setPoetype] = useState("");
  const [sataports, setSataports] = useState("");
  const [length, setLength] = useState("");
  const [screensize, setScreensize] = useState("");
  const [ledtype, setLedtype] = useState("");
  const [size, setSize] = useState("");
  const [lens, setLens] = useState("");
  const [brand, setBrand] = useState("");
  const [NightVision, setNightVision] = useState("");
  const [AudioType, setAudioType] = useState("");
  useEffect(() => {
    console.log('brand' + brand)
    async function fetchFilterNameData() {
      try {
        const response = await axios(baseUrl + "Apicontroller/view_filter/" + mcategory + '/' + user_phone + '/' + user_authentication);
        console.log("Filter name:", response.data.data, response.data.status);
        if (response.data.status === 200) {
          setFN(response.data.data[0]);
        }
        // setAP(response.data.data);
      } catch {
        console.log("Issue in filter name");
      }
    }
    fetchFilterNameData();
  }, [mcategory]);
  //   const cl_remove = (e) => {
  //   setTimeout(() => { document.getElementById(e).classList.remove('show'); }, 1000);
  // }
  const setFilters = (e) => {
    // alert(e.target.checked);
    // console.log("e k andar", e.target.attributes.filterid, e.target.id);
    const filterId = document
      .getElementById(e.target.id)
      // alert(filterId)
      .getAttribute("filterId");
    const filterName = document
      .getElementById(e.target.id)
      .getAttribute("filterName");
    // const filterApiInputTemp = {...filterApiInput};
    var filterApiInputTemp = {};
    // alert("hiiiii")
    if (filterName === "brand") {
      // alert("hiiiii")
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (brand === "") {
          setBrand(filterId);
        } else {
          let newdata = brand + "," + filterId;
          setBrand(newdata);
        }
      } else {
        if (brand !== "") {
          let prevArray = brand.split(",");
          let index = brand.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          // console.log(
          //   "index when removing",
          //   index,
          //   brand.split(","),
          //   prevArray,
          //   prevArray.join()
          // );
          let newdata = prevArray.join();
          setBrand(newdata);
        }
      }
    }
    if (filterName === "resoulation") {
      if (e.target.checked) {
        // console.log("Add this filter api:", filterName, filterId);
        if (resoulation === "") {
          setresoulation(filterId);
        } else {
          let newdata = resoulation + "," + filterId;
          setresoulation(newdata);
        }
      } else {
        if (resoulation !== "") {
          let prevArray = resoulation.split(",");
          let index = resoulation.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          // console.log(
          //   "index when removing",
          //   index,
          //   resoulation.split(","),
          //   prevArray,
          //   prevArray.join()
          // );
          let newdata = prevArray.join();
          setresoulation(newdata);
        }
      }
    }
    if (filterName === "irdistance") {
      if (e.target.checked) {
        // console.log("Add this filter api:", filterName, filterId);
        if (irdistance === "") {
          setIrdistance(filterId);
        } else {
          let newdata = irdistance + "," + filterId;
          setIrdistance(newdata);
        }
      } else {
        if (irdistance !== "") {
          let prevArray = irdistance.split(",");
          let index = irdistance.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          // console.log(
          //   "index when removing",
          //   index,
          //   irdistance.split(","),
          //   prevArray,
          //   prevArray.join()
          // );
          let newdata = prevArray.join();
          setIrdistance(newdata);
        }
      }
    }
    if (filterName === "cameratype") {
      if (e.target.checked) {
        // console.log("Add this filter api:", filterName, filterId);
        if (cameratype === "") {
          setCameratype(filterId);
        } else {
          let newdata = cameratype + "," + filterId;
          setCameratype(newdata);
        }
      } else {
        if (cameratype !== "") {
          let prevArray = cameratype.split(",");
          let index = cameratype.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          // console.log(
          //   "index when removing",
          //   index,
          //   cameratype.split(","),
          //   prevArray,
          //   prevArray.join()
          // );
          let newdata = prevArray.join();
          setCameratype(newdata);
        }
      }
    }
    if (filterName === "bodymaterial") {
      if (e.target.checked) {
        // console.log("Add this filter api:", filterName, filterId);
        if (bodymaterial === "") {
          setBodymaterial(filterId);
        } else {
          let newdata = bodymaterial + "," + filterId;
          setBodymaterial(newdata);
        }
      } else {
        if (bodymaterial !== "") {
          let prevArray = bodymaterial.split(",");
          let index = bodymaterial.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          // console.log(
          //   "index when removing",
          //   index,
          //   bodymaterial.split(","),
          //   prevArray,
          //   prevArray.join()
          // );
          let newdata = prevArray.join();
          setBodymaterial(newdata);
        }
      }
    }
    if (filterName === "videochannel") {
      if (e.target.checked) {
        // console.log("Add this filter api:", filterName, filterId);
        if (videochannel === "") {
          setVideochannel(filterId);
        } else {
          let newdata = videochannel + "," + filterId;
          setVideochannel(newdata);
        }
      } else {
        if (videochannel !== "") {
          let prevArray = videochannel.split(",");
          let index = videochannel.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          // console.log(
          //   "index when removing",
          //   index,
          //   videochannel.split(","),
          //   prevArray,
          //   prevArray.join()
          // );
          let newdata = prevArray.join();
          setVideochannel(newdata);
        }
      }
    }
    if (filterName === "poeports") {
      if (e.target.checked) {
        // console.log("Add this filter api:", filterName, filterId);
        if (poeports === "") {
          setPoeports(filterId);
        } else {
          let newdata = poeports + "," + filterId;
          setPoeports(newdata);
        }
      } else {
        if (poeports !== "") {
          let prevArray = poeports.split(",");
          let index = poeports.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          // console.log(
          //   "index when removing",
          //   index,
          //   poeports.split(","),
          //   prevArray,
          //   prevArray.join()
          // );
          let newdata = prevArray.join();
          setPoeports(newdata);
        }
      }
    }
    if (filterName === "poetype") {
      if (e.target.checked) {
        // console.log("Add this filter api:", filterName, filterId);
        if (poetype === "") {
          setPoetype(filterId);
        } else {
          let newdata = poetype + "," + filterId;
          setPoetype(newdata);
        }
      } else {
        if (poetype !== "") {
          let prevArray = poetype.split(",");
          let index = poetype.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          // console.log(
          //   "index when removing",
          //   index,
          //   poetype.split(","),
          //   prevArray,
          //   prevArray.join()
          // );
          let newdata = prevArray.join();
          setPoetype(newdata);
        }
      }
    }
    if (filterName === "sataports") {
      if (e.target.checked) {
        // console.log("Add this filter api:", filterName, filterId);
        if (sataports === "") {
          setSataports(filterId);
        } else {
          let newdata = sataports + "," + filterId;
          setSataports(newdata);
        }
      } else {
        if (sataports !== "") {
          let prevArray = sataports.split(",");
          let index = sataports.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          // console.log(
          //   "index when removing",
          //   index,
          //   sataports.split(","),
          //   prevArray,
          //   prevArray.join()
          // );
          let newdata = prevArray.join();
          setSataports(newdata);
        }
      }
    }
    if (filterName === "length") {
      if (e.target.checked) {
        // console.log("Add this filter api:", filterName, filterId);
        if (length === "") {
          setLength(filterId);
        } else {
          let newdata = length + "," + filterId;
          setLength(newdata);
        }
      } else {
        if (length !== "") {
          let prevArray = length.split(",");
          let index = length.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          // console.log(
          //   "index when removing",
          //   index,
          //   length.split(","),
          //   prevArray,
          //   prevArray.join()
          // );
          let newdata = prevArray.join();
          setLength(newdata);
        }
      }
    }
    if (filterName === "screensize") {
      if (e.target.checked) {
        // console.log("Add this filter api:", filterName, filterId);
        if (screensize === "") {
          setScreensize(filterId);
        } else {
          let newdata = screensize + "," + filterId;
          setScreensize(newdata);
        }
      } else {
        if (screensize !== "") {
          let prevArray = screensize.split(",");
          let index = screensize.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          // console.log(
          //   "index when removing",
          //   index,
          //   screensize.split(","),
          //   prevArray,
          //   prevArray.join()
          // );
          let newdata = prevArray.join();
          setScreensize(newdata);
        }
      }
    }
    if (filterName === "ledtype") {
      if (e.target.checked) {
        // console.log("Add this filter api:", filterName, filterId);
        if (ledtype === "") {
          setLedtype(filterId);
        } else {
          let newdata = ledtype + "," + filterId;
          setLedtype(newdata);
        }
      } else {
        if (ledtype !== "") {
          let prevArray = ledtype.split(",");
          let index = ledtype.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          // console.log(
          //   "index when removing",
          //   index,
          //   ledtype.split(","),
          //   prevArray,
          //   prevArray.join()
          // );
          let newdata = prevArray.join();
          setLedtype(newdata);
        }
      }
    }
    if (filterName === "size") {
      if (e.target.checked) {
        // console.log("Add this filter api:", filterName, filterId);
        if (size === "") {
          setSize(filterId);
        } else {
          let newdata = size + "," + filterId;
          setSize(newdata);
        }
      } else {
        if (size !== "") {
          let prevArray = size.split(",");
          let index = size.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          // console.log(
          //   "index when removing",
          //   index,
          //   size.split(","),
          //   prevArray,
          //   prevArray.join()
          // );
          let newdata = prevArray.join();
          setSize(newdata);
        }
      }
    }
    if (filterName === "lens") {
      if (e.target.checked) {
        // console.log("Add this filter api:", filterName, filterId);
        if (lens === "") {
          setLens(filterId);
        } else {
          let newdata = lens + "," + filterId;
          setLens(newdata);
        }
      } else {
        if (lens !== "") {
          let prevArray = lens.split(",");
          let index = lens.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          // console.log(
          //   "index when removing",
          //   index,
          //   lens.split(","),
          //   prevArray,
          //   prevArray.join()
          // );
          let newdata = prevArray.join();
          setLens(newdata);
        }
      }
    }
    //---------------- Night vision --------------
    if (filterName === "night_vision") {
      if (e.target.checked) {
        // console.log("Add this filter api:", filterName, filterId);
        if (NightVision === "") {
          setNightVision(filterId);
        } else {
          let newdata = NightVision + "," + filterId;
          setNightVision(newdata);
        }
      } else {
        if (NightVision !== "") {
          let prevArray = NightVision.split(",");
          let index = NightVision.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          // console.log(
          //   "index when removing",
          //   index,
          //   lens.split(","),
          //   prevArray,
          //   prevArray.join()
          // );
          let newdata = prevArray.join();
          setNightVision(newdata);
        }
      }
    }
    //---------------- audio type --------------
    if (filterName === "audio_type") {
      if (e.target.checked) {
        // console.log("Add this filter api:", filterName, filterId);
        if (AudioType === "") {
          setAudioType(filterId);
        } else {
          let newdata = AudioType + "," + filterId;
          setAudioType(newdata);
        }
      } else {
        if (AudioType !== "") {
          let prevArray = AudioType.split(",");
          let index = AudioType.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          // console.log(
          //   "index when removing",
          //   index,
          //   lens.split(","),
          //   prevArray,
          //   prevArray.join()
          // );
          let newdata = prevArray.join();
          setAudioType(newdata);
        }
      }
    }
  };
  const applyingFilterAPI = async () => {
    // console.log("brand", brand);
    if (
      brand !== "" ||
      resoulation !== "" ||
      irdistance !== "" ||
      cameratype !== "" ||
      bodymaterial !== "" ||
      videochannel !== "" ||
      poeports !== "" ||
      poetype !== "" ||
      sataports !== "" ||
      length !== "" ||
      screensize !== "" ||
      ledtype !== "" ||
      size !== "" ||
      lens !== "" ||
      NightVision !== "" ||
      AudioType !== ""
    ) {
      // alert("hi")
      // console.log("Applying filter api");
      const appliedFilterData = new FormData();
      appliedFilterData.append("brand_id", brand);
      appliedFilterData.append("resolution_id", resoulation);
      appliedFilterData.append("irdistance_id", irdistance);
      appliedFilterData.append("cameratype_id", cameratype);
      appliedFilterData.append("bodymaterial_id", bodymaterial);
      appliedFilterData.append("videochannel_id", videochannel);
      appliedFilterData.append("poeports_id", poeports);
      appliedFilterData.append("poetype_id", poetype);
      appliedFilterData.append("sataports_id", sataports);
      appliedFilterData.append("length_id", length);
      appliedFilterData.append("screensize_id", screensize);
      appliedFilterData.append("ledtype_id", ledtype);
      appliedFilterData.append("size_id", size);
      appliedFilterData.append("lens_id", lens);
      appliedFilterData.append("night_vision_id", NightVision);
      appliedFilterData.append("audio_type_id", AudioType);
      appliedFilterData.append("minorcategory_id", mcategory);
      appliedFilterData.append("phone", user_phone);
      appliedFilterData.append("authentication", user_authentication);
      for (let pair of appliedFilterData.entries()) {
        // console.log(pair[0], ":", pair[1]);
      }
      try {
        const response = await axios.post(baseUrl + "Apicontroller/filter_new", appliedFilterData);
        console.log("filtered product data", response);
        if (response.data.status === 201) {
          setallProduct([]);
          setEmptyMessage("No product available.");
        } else {
          console.log("filtered product data", response.data.data);
          setallProduct(response.data.data);
          setEmptyMessage("");
        }
      } catch {
        console.log("Issue in get filterd data");
      }
    } else {
      fetchAllProductData();
    }
  };
  const [productsToShow, setProductsToShow] = useState(16);
  const showMoreProducts = () => {
    let totalNewProduct = productsToShow + 16;
    let num = allproduct.length;
    if (totalNewProduct < num) {
      setProductsToShow(totalNewProduct);
    } else {
      setProductsToShow(num);
      document.getElementById("showmoreproducts").style.display = "none";
    }
  };
  return (
    <div>
      {/* filter */}
      <section className="filter pt-5 mt-5 accordion" id="accordionExample" >
        <div className="container" style={{ maxWidth: "100vw", zIndex: 999 }}>
          <div class="row heading-div">
            <div class="col-md-12 text-center mb-2">
              <h1 className="heading" style={{ marginBottom: "0px" }}>{minorcategory}</h1>
              <img src="../img/bottom.png" style={{ width: "13rem", marginTop: "-10px", marginBottom: "10px" }}></img>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-5 d-block d-lg-none d-md-none">
              <button
                type="button"
                className="btn btn-filter"
                data-toggle="modal"
                data-target="#myModal"
              >
                <img
                  style={{ width: "30px" }}
                  className="mr-3"
                  src="../img/filter.png"
                />{" "}
                FILTER
              </button>
              {/* <!-- filter model start --> */}
              <div
                className="modal"
                id="myModal"
                aria-modal="true"
                role="dialog"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    {/* <!-- Modal Header --> */}
                    <div className="modal-header">
                      <h4 className="modal-title">FILTER</h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                      >
                        ×
                      </button>
                    </div>
                    {/* <!-- Modal body --> */}
                    <div className="modal-body p-0">
                      <ul className="d-block">
                        <li className="ml-3">
                          <img
                            style={{ width: "30px" }}
                            src="../img/filter.png"
                          />
                          <button
                            className="collapsed btn btn-primary float-right mt-2"
                            data-dismiss="modal"
                            onClick={() => {
                              return applyingFilterAPI()
                            }}
                            style={{
                              background: "#d7314a",
                              color: "white",
                              border: "none"
                            }}
                            type="button"
                            data-toggle="collapse"
                            // data-target="#collapseall"
                            aria-expanded="false"
                          // aria-controls="collapseall"
                          >
                            Apply
                          </button>
                        </li>
                        {FilterName.brand.length
                          ?
                          <li id="headingOnee">
                            <a
                              className=""
                              data-toggle="collapse"
                              type="button"
                              data-target="#collapseOnee"
                              aria-expanded="true"
                              aria-controls="collapseOnee"
                            >
                              Brand
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li> : null}
                        <div
                          className="container collapse p-0"
                          id="collapseOnee"
                          aria-labelledby="headingOnee"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {/* console.log("hello:",FilterName.brand) */}
                            {FilterName.brand
                              ? FilterName.brand.map((elem, i) => {
                                let filterId = i + 1;
                                return (
                                  <div className="d-flex py-1 px-3">
                                    <input
                                      type="checkbox"
                                      id={"brand" + elem.id}
                                      filterid={elem.id}
                                      filtername="brand"
                                      onClick={(e) => setFilters(e)}
                                    />
                                    <span className="sub_sp">{elem.name}</span>
                                    {/* <span className="sub_sp1">(17)</span> */}
                                  </div>
                                );
                              })
                              : ""}
                          </div>
                        </div>
                        {FilterName.resoulation.length
                          ?
                          <li id="headingTwoo">
                            <a
                              className="collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTwoo"
                              aria-expanded="false"
                              aria-controls="collapseTwoo"
                            >
                              Resolution
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li> : null}
                        <div
                          className="container collapse p-0"
                          id="collapseTwoo"
                          aria-labelledby="headingTwoo"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.resoulation
                              ? FilterName.resoulation.map((elem, i) => {
                                // let filterId = i + 1;
                                return (
                                  <div className="d-flex py-1 px-3">
                                    <input
                                      type="checkbox"
                                      id={"resoulation" + elem.id}
                                      filterid={elem.id}
                                      filtername="resoulation"
                                      onClick={(e) => setFilters(e)}
                                    />{" "}
                                    <span className="sub_sp">{elem.name}</span>{" "}
                                    {/* <span className="sub_sp1">(17)</span> */}
                                  </div>
                                );
                              })
                              : null}
                          </div>
                        </div>
                        {
                          FilterName.lens.length
                            ?
                            <li id="headingThreee">
                              <a
                                className="collapsed"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseThreee"
                                aria-expanded="false"
                                aria-controls="collapseThreee"
                              >
                                Lens
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </li> : null}
                        <div
                          className="container collapse p-0"
                          id="collapseThreee"
                          aria-labelledby="headingThreee"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {
                              FilterName.lens
                                ? FilterName.lens.map((elem, i) => {
                                  let filterId = i + 1;
                                  return (
                                    <div className="d-flex py-1 px-3">
                                      <input
                                        type="checkbox"
                                        id={"lens" + elem.id}
                                        filterid={elem.id}
                                        filtername="lens"
                                        onClick={(e) => setFilters(e)}
                                      />{" "}
                                      <span className="sub_sp">{elem.name}</span>{" "}
                                      {/* <span className="sub_sp1">(17)</span> */}
                                    </div>
                                  );
                                })
                                : null}
                          </div>
                        </div>
                        {FilterName.irdistance.length
                          ?
                          <li id="headingfourr">
                            <a
                              className="collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapsefourr"
                              aria-expanded="false"
                              aria-controls="collapsefourr"
                            >
                              IR Distance
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li> : null}
                        <div
                          className="container collapse p-0"
                          id="collapsefourr"
                          aria-labelledby="headingfourr"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.irdistance
                              ? FilterName.irdistance.map((elem, i) => {
                                let filterId = i + 1;
                                return (
                                  <div className="d-flex py-1 px-3">
                                    <input
                                      type="checkbox"
                                      id={"irdistance" + elem.id}
                                      filterid={elem.id}
                                      filtername="irdistance"
                                      onClick={(e) => setFilters(e)}
                                    />{" "}
                                    <span className="sub_sp">{elem.name}</span>{" "}
                                    {/* <span className="sub_sp1">(17)</span> */}
                                  </div>
                                );
                              })
                              : null}
                          </div>
                        </div>
                        {FilterName.cameratype.length
                          ?
                          <li id="headingfivee">
                            <a
                              className="collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapsefivee"
                              aria-expanded="false"
                              aria-controls="collapsefivee"
                            >
                              Body Type
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li> : null}
                        <div
                          className="container collapse p-0"
                          id="collapsefivee"
                          aria-labelledby="headingfivee"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.cameratype
                              ? FilterName.cameratype.map((elem, i) => {
                                let filterId = i + 1;
                                return (
                                  <div className="d-flex py-1 px-3">
                                    <input
                                      type="checkbox"
                                      id={"cameratype" + elem.id}
                                      filterid={elem.id}
                                      filtername="cameratype"
                                      onClick={(e) => setFilters(e)}
                                    />{" "}
                                    <span className="sub_sp">
                                      {elem.name}
                                    </span>{" "}
                                    {/* <span className="sub_sp1">(17)</span> */}
                                  </div>
                                );
                              })
                              : null}
                          </div>
                        </div>
                        {FilterName.bodymaterial.length
                          ?
                          <li id="headingsixx">
                            <a
                              className="collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapsesixx"
                              aria-expanded="false"
                              aria-controls="collapsesixx"
                            >
                              Body Material
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li> : null}
                        <div
                          className="container collapse p-0"
                          id="collapsesixx"
                          aria-labelledby="headingsixx"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.bodymaterial
                              ? FilterName.bodymaterial.map((elem, i) => {
                                let filterId = i + 1;
                                return (
                                  <div className="d-flex py-1 px-3">
                                    <input
                                      type="checkbox"
                                      id={"bodymaterial" + elem.id}
                                      filterid={elem.id}
                                      filtername="bodymaterial"
                                      onClick={(e) => setFilters(e)}
                                    />{" "}
                                    <span className="sub_sp">
                                      {elem.name}
                                    </span>{" "}
                                    {/* <span className="sub_sp1">(17)</span> */}
                                  </div>
                                );
                              })
                              : null}
                          </div>
                        </div>
                        {FilterName.videochannel.length
                          ?
                          <li id="headingsevenn">
                            <a
                              className="collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapsesevenn"
                              aria-expanded="false"
                              aria-controls="collapsesevenn"
                            >
                              NO OF CHANNEL
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li> : null}
                        <div
                          className="container collapse p-0"
                          id="collapsesevenn"
                          aria-labelledby="headingsevenn"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.videochannel
                              ? FilterName.videochannel.map((elem, i) => {
                                let filterId = i + 1;
                                return (
                                  <div className="d-flex py-1 px-3">
                                    <input
                                      type="checkbox"
                                      id={"videochannel" + elem.id}
                                      filterid={elem.id}
                                      filtername="videochannel"
                                      onClick={(e) => setFilters(e)}
                                    />{" "}
                                    <span className="sub_sp">
                                      {elem.name}
                                    </span>{" "}
                                    {/* <span className="sub_sp1">(17)</span> */}
                                  </div>
                                );
                              })
                              : null}
                          </div>
                        </div>
                        {FilterName.poeports.length
                          ?
                          <li id="headingeightt">
                            <a
                              className="collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseeightt"
                              aria-expanded="false"
                              aria-controls="collapseeightt"
                            >
                              POE PORTS
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li> : null}
                        <div
                          className="container collapse p-0"
                          id="collapseeightt"
                          aria-labelledby="headingeightt"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.poeports
                              ? FilterName.poeports.map((elem, i) => {
                                let filterId = i + 1;
                                return (
                                  <div className="d-flex py-1 px-3">
                                    <input
                                      type="checkbox"
                                      id={"poeports" + elem.id}
                                      filterid={elem.id}
                                      filtername="poeports"
                                      onClick={(e) => setFilters(e)}
                                    />{" "}
                                    <span className="sub_sp">
                                      {elem.name}
                                    </span>{" "}
                                    {/* <span className="sub_sp1">(17)</span> */}
                                  </div>
                                );
                              })
                              : null}
                          </div>
                        </div>
                        {FilterName.sataports.length
                          ?
                          <li id="headingninee">
                            <a
                              className="collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseninee"
                              aria-expanded="false"
                              aria-controls="collapseninee"
                            >
                              SATA PORTS
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li> : null}
                        <div
                          className="container collapse p-0"
                          id="collapseninee"
                          aria-labelledby="headingninee"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.sataports
                              ? FilterName.sataports.map((elem, i) => {
                                let filterId = i + 1;
                                return (
                                  <div className="d-flex py-1 px-3">
                                    <input
                                      type="checkbox"
                                      id={"sataports" + elem.id}
                                      filterid={elem.id}
                                      filtername="sataports"
                                      onClick={(e) => setFilters(e)}
                                    />{" "}
                                    <span className="sub_sp">
                                      {elem.name}
                                    </span>{" "}
                                    {/* <span className="sub_sp1">(17)</span> */}
                                  </div>
                                );
                              })
                              : null}
                          </div>
                        </div>
                        {FilterName.lengths.length ?
                          <li id="headingtenn">
                            <a
                              className="collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapsetenn"
                              aria-expanded="false"
                              aria-controls="collapsetenn"
                            >
                              LENGTH
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li> : null}
                        <div
                          className="container collapse p-0"
                          id="collapsetenn"
                          aria-labelledby="headingtenn"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.lengths
                              ? FilterName.lengths.map((elem, i) => {
                                let filterId = i + 1;
                                return (
                                  <div className="d-flex py-1 px-3">
                                    <input
                                      type="checkbox"
                                      id={"length" + elem.id}
                                      filterid={elem.id}
                                      filtername="length"
                                      onClick={(e) => setFilters(e)}
                                    />{" "}
                                    <span className="sub_sp">
                                      {elem.name}
                                    </span>{" "}
                                    {/* <span className="sub_sp1">(17)</span> */}
                                  </div>
                                );
                              })
                              : null}
                          </div>
                        </div>
                        {FilterName.screensize.length
                          ?
                          <li id="headingelevenn">
                            <a
                              className="collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseelevenn"
                              aria-expanded="false"
                              aria-controls="collapseelevenn"
                            >
                              SCREEN SIZE
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li> : null}
                        <div
                          className="container collapse p-0"
                          id="collapseelevenn"
                          aria-labelledby="headingelevenn"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.screensize
                              ? FilterName.screensize.map((elem, i) => {
                                let filterId = i + 1;
                                return (
                                  <div className="d-flex py-1 px-3">
                                    <input
                                      type="checkbox"
                                      id={"screensize" + elem.id}
                                      filterid={elem.id}
                                      filtername="screensize"
                                      onClick={(e) => setFilters(e)}
                                    />{" "}
                                    <span className="sub_sp">
                                      {elem.name}
                                    </span>{" "}
                                    {/* <span className="sub_sp1">(17)</span> */}
                                  </div>
                                );
                              })
                              : null}
                          </div>
                        </div>
                        {FilterName.ledtype.length
                          ?
                          <li id="headingtwelvee">
                            <a
                              className="collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapsetwelvee"
                              aria-expanded="false"
                              aria-controls="collapsetwelvee"
                            >
                              FEATURES
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li> : null}
                        <div
                          className="container collapse p-0"
                          id="collapsetwelvee"
                          aria-labelledby="headingtwelvee"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.ledtype
                              ? FilterName.ledtype.map((elem, i) => {
                                let filterId = i + 1;
                                return (
                                  <div className="d-flex py-1 px-3">
                                    <input
                                      type="checkbox"
                                      id={"ledtype" + elem.id}
                                      filterid={elem.id}
                                      filtername="ledtype"
                                      onClick={(e) => setFilters(e)}
                                    />{" "}
                                    <span className="sub_sp">
                                      {elem.name}
                                    </span>{" "}
                                    {/* <span className="sub_sp1">(17)</span> */}
                                  </div>
                                );
                              })
                              : null}
                          </div>
                        </div>
                        {FilterName.size.length
                          ?
                          <li id="headingthirteenn">
                            <a
                              className="collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapsethirteenn"
                              aria-expanded="false"
                              aria-controls="collapsethirteenn"
                            >
                              SIZE
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li> : null}
                        <div
                          className="container collapse p-0"
                          id="collapsethirteenn"
                          aria-labelledby="headingthirteenn"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.size
                              ? FilterName.size.map((elem, i) => {
                                let filterId = i + 1;
                                return (
                                  <div className="d-flex py-1 px-3">
                                    <input
                                      type="checkbox"
                                      id={"size" + elem.id}
                                      filterid={elem.id}
                                      filtername="size"
                                      onClick={(e) => setFilters(e)}
                                    />{" "}
                                    <span className="sub_sp">
                                      {elem.name}
                                    </span>{" "}
                                    {/* <span className="sub_sp1">(17)</span> */}
                                  </div>
                                );
                              })
                              : null}
                          </div>
                        </div>
                        {/* //--------------night vision ----------------------- */}
                        {FilterName.night_vision.length
                          ?
                          <li id="headingfourteenn">
                            <a
                              className="collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapsefourteenn"
                              aria-expanded="false"
                              aria-controls="collapsefourteenn"
                            >
                              Night Vision
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li> : null}
                        <div
                          className="container collapse p-0"
                          id="collapsefourteenn"
                          aria-labelledby="headingfourteenn"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.night_vision
                              ? FilterName.night_vision.map((elem, i) => {
                                let filterId = i + 1;
                                return (
                                  <div className="d-flex py-1 px-3">
                                    <input
                                      type="checkbox"
                                      id={"night_vision" + elem.id}
                                      filterid={elem.id}
                                      filtername="night_vision"
                                      onClick={(e) => setFilters(e)}
                                    />{" "}
                                    <span className="sub_sp">
                                      {elem.name}
                                    </span>{" "}
                                    {/* <span className="sub_sp1">(17)</span> */}
                                  </div>
                                );
                              })
                              : null}
                          </div>
                        </div>
                        {/* //--------------audio_type vision ----------------------- */}
                        {FilterName.audio_type.length
                          ?
                          <li id="headingfifteenn">
                            <a
                              className="collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapsefifteenn"
                              aria-expanded="false"
                              aria-controls="collapsefifteenn"
                            >
                              Audio Type
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li> : null}
                        <div
                          className="container collapse p-0"
                          id="collapsefifteenn"
                          aria-labelledby="headingfifteenn"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.audio_type
                              ? FilterName.audio_type.map((elem, i) => {
                                let filterId = i + 1;
                                return (
                                  <div className="d-flex py-1 px-3">
                                    <input
                                      type="checkbox"
                                      id={"audio_type" + elem.id}
                                      filterid={elem.id}
                                      filtername="audio_type"
                                      onClick={(e) => setFilters(e)}
                                    />{" "}
                                    <span className="sub_sp">
                                      {elem.name}
                                    </span>{" "}
                                    {/* <span className="sub_sp1">(17)</span> */}
                                  </div>
                                );
                              })
                              : null}
                          </div>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-none d-lg-block d-md-block" style={{}}>
              {/* <!-- filter model end --> */}
              <ul>
                <li className="ml-1">
                  <img style={{ width: "30px" }} src="../img/filter.png" />
                </li>
                {FilterName.brand.length
                  ?
                  <li id="headingOne">
                    <a
                      className="this is min anchor"
                      // onClick={()=>setModalDis(!modalDis)}
                      // onClick={()=>cl_remove('collapseOne')}
                      data-toggle="collapse"
                      type="button"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Brand
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </a>
                  </li> : null}
                {FilterName.resoulation.length
                  ?
                  <li id="headingTwo">
                    <a
                      className="collapsed"
                      // onClick={()=>setModalDis(!modalDis)}
                      // onClick={()=>cl_remove('collapseTwo')}
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      resolution
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </a>
                  </li> : null}
                {FilterName.irdistance.length
                  ?
                  <li id="headingThree">
                    <a
                      className="collapsed"
                      // onClick={()=>setModalDis(!modalDis)}
                      // onClick={()=>cl_remove('collapseThree')}
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      IR DISTANCE
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </a>
                  </li> : null}
                {FilterName.cameratype.length
                  ?
                  <li id="headingfour">
                    <a
                      className="collapsed"
                      // onClick={()=>setModalDis(!modalDis)}
                      // onClick={()=>cl_remove('collapsefour')}
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapsefour"
                      aria-expanded="false"
                      aria-controls="collapsefour"
                    >
                      BODY TYPE
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </a>
                  </li> : null}
                {FilterName.bodymaterial.length
                  ?
                  <li id="headingfive">
                    <a
                      className="collapsed"
                      // onClick={()=>setModalDis(!modalDis)}
                      // onClick={()=>cl_remove('collapsefive')}
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapsefive"
                      aria-expanded="false"
                      aria-controls="collapsefive"
                    >
                      BODY MATERIAL
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </a>
                  </li> : null}
                {FilterName.videochannel.length
                  ?
                  <li id="headingsix">
                    <a
                      className="collapsed"
                      // onClick={()=>setModalDis(!modalDis)}
                      // onClick={()=>cl_remove('collapsesix')}
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapsesix"
                      aria-expanded="false"
                      aria-controls="collapsesix"
                    >
                      NO OF CHANNEL
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </a>
                  </li> : null}
                {FilterName.poeports.length
                  ?
                  <li id="headingseven">
                    <a
                      className="collapsed"
                      // onClick={()=>setModalDis(!modalDis)}
                      // onClick={()=>cl_remove('collapseseven')}
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseseven"
                      aria-expanded="false"
                      aria-controls="collapseseven"
                    >
                      POE PORTS
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </a>
                  </li> : null}
                {FilterName.poetype.length
                  ?
                  <li id="headingeight">
                    <a
                      className="collapsed"
                      // onClick={()=>setModalDis(!modalDis)}
                      // onClick={()=>cl_remove('collapseeight')}
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseeight"
                      aria-expanded="false"
                      aria-controls="collapseeight"
                    >
                      POE TYPES
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </a>
                  </li> : null}
                {FilterName.sataports.length
                  ?
                  <li id="headingnine">
                    <a
                      className="collapsed"
                      // onClick={()=>setModalDis(!modalDis)}
                      // onClick={()=>cl_remove('collapsenine')}
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapsenine"
                      aria-expanded="false"
                      aria-controls="collapsenine"
                    >
                      SATA PORTS
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </a>
                  </li> : null}
                {FilterName.lengths.length ?
                  <li id="headingten">
                    <a
                      className="collapsed"
                      // onClick={()=>setModalDis(!modalDis)}
                      // onClick={()=>cl_remove('collapseten')}
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseten"
                      aria-expanded="false"
                      aria-controls="collapseten"
                    >
                      LENGTH
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </a>
                  </li> : null}
                {FilterName.screensize.length
                  ?
                  <li id="headingeleven">
                    <a
                      className="collapsed"
                      // onClick={()=>setModalDis(!modalDis)}
                      // onClick={()=>cl_remove('collapseeleven')}
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseeleven"
                      aria-expanded="false"
                      aria-controls="collapseeleven"
                    >
                      SCREEN SIZE
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </a>
                  </li> : null}
                {FilterName.ledtype.length
                  ?
                  <li id="headingtwelve">
                    <a
                      className="collapsed"
                      // onClick={()=>setModalDis(!modalDis)}
                      // onClick={()=>cl_remove('collapsetwelve')}
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapsetwelve"
                      aria-expanded="false"
                      aria-controls="collapsetwelve"
                    >
                      FEATURES
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </a>
                  </li> : null}
                {FilterName.size.length
                  ?
                  <li id="headingthirteen">
                    <a
                      className="collapsed"
                      // onClick={()=>setModalDis(!modalDis)}
                      // onClick={()=>cl_remove('collapsethirteen')}
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapsethirteen"
                      aria-expanded="false"
                      aria-controls="collapsethirteen"
                    >
                      SIZE
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </a>
                  </li> : null}
                {FilterName.lens.length
                  ?
                  <li id="headingfourteen">
                    <a
                      className="collapsed"
                      // onClick={()=>setModalDis(!modalDis)}
                      // onClick={()=>cl_remove('collapsefourteen')}
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapsefourteen"
                      aria-expanded="false"
                      aria-controls="collapsefourteen"
                    >
                      LENS
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </a>
                  </li> : null}
                {/* //------------------------------- night vision ------------------ */}
                {FilterName.night_vision.length
                  ?
                  <li id="headingfifteen">
                    <a
                      className="collapsed"
                      // onClick={()=>setModalDis(!modalDis)}
                      // onClick={()=>cl_remove('collapsethirteen')}
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapsefifteen"
                      aria-expanded="false"
                      aria-controls="collapsefifteen"
                    >
                      Night Vision
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </a>
                  </li> : null}
                {/* //---------------------------- audio type ------------------                 */}
                {FilterName.audio_type.length
                  ?
                  <li id="headingsixteen">
                    <a
                      className="collapsed"
                      // onClick={()=>setModalDis(!modalDis)}
                      // onClick={()=>cl_remove('collapsefourteen')}
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapsesixteen"
                      aria-expanded="false"
                      aria-controls="collapsesixteen"
                    >
                      Audio Type
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </a>
                  </li> : null}
                <li>
                  <button
                    className="collapsed btn btn-primary"
                    onClick={() => {
                      setModalDis(!modalDis)
                      return applyingFilterAPI()
                    }}
                    style={{
                      background: "#d7314a",
                      color: "white",
                      border: "none"
                    }}
                    type="button"
                    data-toggle="collapse"
                    // data-target="#collapseall"
                    aria-expanded="false"
                  // aria-controls="collapseall"
                  >
                    Apply
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis ? "container collapse " : "container collapse"}
          id="collapseOne"
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.brand
              ? FilterName.brand.map((elem, i) => {
                let filterId = i + 1;
                return (
                  <div className="d-flex py-3 px-3">
                    <input
                      type="checkbox"
                      id={"brand" + elem.id}
                      filterid={elem.id}
                      filtername="brand"
                      onClick={(e) => setFilters(e)}
                    />{" "}
                    <span className="sub_sp">{elem.name}</span>{" "}
                    {/* <span className="sub_sp1">(17)</span> */}
                  </div>
                );
              })
              : ""}
          </div>
        </div>
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis ? "container collapse " : "container collapse"}
          id="collapseTwo"
          aria-labelledby="headingTwo"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.resoulation
              ? FilterName.resoulation.map((elem, i) => {
                let filterId = i + 1;
                return (
                  <div className="d-flex py-3 px-3">
                    <input
                      type="checkbox"
                      id={"resoulation" + elem.id}
                      filterid={elem.id}
                      filtername="resoulation"
                      onClick={(e) => setFilters(e)}
                    />{" "}
                    <span className="sub_sp">{elem.name}</span>{" "}
                    {/* <span className="sub_sp1">(17)</span> */}
                  </div>
                );
              })
              : null}
          </div>
        </div>
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis ? "container collapse " : "container collapse"}
          id="collapseThree"
          aria-labelledby="headingThree"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.irdistance
              ? FilterName.irdistance.map((elem, i) => {
                let filterId = i + 1;
                return (
                  <div className="d-flex py-3 px-3">
                    <input
                      type="checkbox"
                      id={"irdistance" + elem.id}
                      filterid={elem.id}
                      filtername="irdistance"
                      onClick={(e) => setFilters(e)}
                    />{" "}
                    <span className="sub_sp">{elem.name}</span>{" "}
                    {/* <span className="sub_sp1">(17)</span> */}
                  </div>
                );
              })
              : null}
          </div>
        </div>
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis ? "container collapse " : "container collapse"}
          id="collapsefour"
          aria-labelledby="headingfour"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.cameratype
              ? FilterName.cameratype.map((elem, i) => {
                let filterId = i + 1;
                return (
                  <div className="d-flex py-3 px-3">
                    <input
                      type="checkbox"
                      id={"cameratype" + elem.id}
                      filterid={elem.id}
                      filtername="cameratype"
                      onClick={(e) => setFilters(e)}
                    />{" "}
                    <span className="sub_sp">{elem.name}</span>{" "}
                    {/* <span className="sub_sp1">(17)</span> */}
                  </div>
                );
              })
              : null}
          </div>
        </div>
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis ? "container collapse " : "container collapse"}
          id="collapsefive"
          aria-labelledby="headingfive"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.bodymaterial
              ? FilterName.bodymaterial.map((elem, i) => {
                let filterId = i + 1;
                return (
                  <div className="d-flex py-3 px-3">
                    <input
                      type="checkbox"
                      id={"bodymaterial" + elem.id}
                      filterid={elem.id}
                      filtername="bodymaterial"
                      onClick={(e) => setFilters(e)}
                    />{" "}
                    <span className="sub_sp">{elem.name}</span>{" "}
                    {/* <span className="sub_sp1">(17)</span> */}
                  </div>
                );
              })
              : null}
          </div>
        </div>
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis ? "container collapse " : "container collapse"}
          id="collapsesix"
          aria-labelledby="headingsix"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.videochannel
              ? FilterName.videochannel.map((elem, i) => {
                let filterId = i + 1;
                return (
                  <div className="d-flex py-3 px-3">
                    <input
                      type="checkbox"
                      id={"videochannel" + elem.id}
                      filterid={elem.id}
                      filtername="videochannel"
                      onClick={(e) => setFilters(e)}
                    />{" "}
                    <span className="sub_sp">{elem.name}</span>{" "}
                    {/* <span className="sub_sp1">(17)</span> */}
                  </div>
                );
              })
              : null}
          </div>
        </div>
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis ? "container collapse " : "container collapse"}
          id="collapseseven"
          aria-labelledby="headingseven"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.poeports
              ? FilterName.poeports.map((elem, i) => {
                let filterId = i + 1;
                return (
                  <div className="d-flex py-3 px-3">
                    <input
                      type="checkbox"
                      id={"poeports" + elem.id}
                      filterid={elem.id}
                      filtername="poeports"
                      onClick={(e) => setFilters(e)}
                    />{" "}
                    <span className="sub_sp">{elem.name}</span>{" "}
                    {/* <span className="sub_sp1">(17)</span> */}
                  </div>
                );
              })
              : null}
          </div>
        </div>
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis ? "container collapse " : "container collapse"}
          id="collapseeight"
          aria-labelledby="headingeight"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.poetype
              ? FilterName.poetype.map((elem, i) => {
                let filterId = i + 1;
                return (
                  <div className="d-flex py-3 px-3">
                    <input
                      type="checkbox"
                      id={"poetype" + elem.id}
                      filterid={elem.id}
                      filtername="poetype"
                      onClick={(e) => setFilters(e)}
                    />{" "}
                    <span className="sub_sp">{elem.name}</span>{" "}
                    {/* <span className="sub_sp1">(17)</span> */}
                  </div>
                );
              })
              : null}
          </div>
        </div>
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis ? "container collapse " : "container collapse"}
          id="collapsenine"
          aria-labelledby="headingnine"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.sataports
              ? FilterName.sataports.map((elem, i) => {
                let filterId = i + 1;
                return (
                  <div className="d-flex py-3 px-3">
                    <input
                      type="checkbox"
                      id={"sataports" + elem.id}
                      filterid={elem.id}
                      filtername="sataports"
                      onClick={(e) => setFilters(e)}
                    />{" "}
                    <span className="sub_sp">{elem.name}</span>{" "}
                    {/* <span className="sub_sp1">(17)</span> */}
                  </div>
                );
              })
              : null}
          </div>
        </div>
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis ? "container collapse " : "container collapse"}
          id="collapseten"
          aria-labelledby="headingten"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.lengths
              ? FilterName.lengths.map((elem, i) => {
                let filterId = i + 1;
                return (
                  <div className="d-flex py-3 px-3">
                    <input
                      type="checkbox"
                      id={"length" + elem.id}
                      filterid={elem.id}
                      filtername="length"
                      onClick={(e) => setFilters(e)}
                    />{" "}
                    <span className="sub_sp">{elem.name}</span>{" "}
                    {/* <span className="sub_sp1">(17)</span> */}
                  </div>
                );
              })
              : null}
          </div>
        </div>
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis ? "container collapse " : "container collapse"}
          id="collapseeleven"
          aria-labelledby="headingeleven"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.screensize
              ? FilterName.screensize.map((elem, i) => {
                let filterId = i + 1;
                return (
                  <div className="d-flex py-3 px-3">
                    <input
                      type="checkbox"
                      id={"screensize" + elem.id}
                      filterid={elem.id}
                      filtername="screensize"
                      onClick={(e) => setFilters(e)}
                    />{" "}
                    <span className="sub_sp">{elem.name}</span>{" "}
                    {/* <span className="sub_sp1">(17)</span> */}
                  </div>
                );
              })
              : null}
          </div>
        </div>
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis ? "container collapse " : "container collapse"}
          id="collapsetwelve"
          aria-labelledby="headingtwelve"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.ledtype
              ? FilterName.ledtype.map((elem, i) => {
                let filterId = i + 1;
                return (
                  <div className="d-flex py-3 px-3">
                    <input
                      type="checkbox"
                      id={"ledtype" + elem.id}
                      filterid={elem.id}
                      filtername="ledtype"
                      onClick={(e) => setFilters(e)}
                    />{" "}
                    <span className="sub_sp">{elem.name}</span>{" "}
                    {/* <span className="sub_sp1">(17)</span> */}
                  </div>
                );
              })
              : null}
          </div>
        </div>
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis ? "container collapse " : "container collapse"}
          id="collapsethirteen"
          aria-labelledby="headingthirteen"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.size
              ? FilterName.size.map((elem, i) => {
                let filterId = i + 1;
                return (
                  <div className="d-flex py-3 px-3">
                    <input
                      type="checkbox"
                      id={"size" + elem.id}
                      filterid={elem.id}
                      filtername="size"
                      onClick={(e) => setFilters(e)}
                    />{" "}
                    <span className="sub_sp">
                      {elem.name}
                    </span>{" "}
                    {/* <span className="sub_sp1">(17)</span> */}
                  </div>
                );
              })
              : null}
          </div>
        </div>
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis ? "container collapse " : "container collapse"}
          id="collapsefourteen"
          aria-labelledby="headingfourteen"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.lens
              ? FilterName.lens.map((elem, i) => {
                let filterId = i + 1;
                return (
                  <div className="d-flex py-3 px-3">
                    <input
                      type="checkbox"
                      id={"lens" + elem.id}
                      filterid={elem.id}
                      filtername="lens"
                      onClick={(e) => setFilters(e)}
                    />{" "}
                    <span className="sub_sp">{elem.name}</span>{" "}
                    {/* <span className="sub_sp1">(17)</span> */}
                  </div>
                );
              })
              : null}
          </div>
        </div>
        {/* //----night vision ------------- */}
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis ? "container collapse " : "container collapse"}
          id="collapsefifteen"
          aria-labelledby="headingfifteen"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.night_vision
              ? FilterName.night_vision.map((elem, i) => {
                let filterId = i + 1;
                return (
                  <div className="d-flex py-3 px-3">
                    <input
                      type="checkbox"
                      id={"night_vision" + elem.id}
                      filterid={elem.id}
                      filtername="night_vision"
                      onClick={(e) => setFilters(e)}
                    />{" "}
                    <span className="sub_sp">{elem.name}</span>{" "}
                    {/* <span className="sub_sp1">(17)</span> */}
                  </div>
                );
              })
              : null}
          </div>
        </div>
        {/* //----audio type ------------- */}
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis ? "container collapse " : "container collapse"}
          id="collapsesixteen"
          aria-labelledby="headingsixteen"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.audio_type
              ? FilterName.audio_type.map((elem, i) => {
                let filterId = i + 1;
                return (
                  <div className="d-flex py-3 px-3">
                    <input
                      type="checkbox"
                      id={"audio_type" + elem.id}
                      filterid={elem.id}
                      filtername="audio_type"
                      onClick={(e) => setFilters(e)}
                    />{" "}
                    <span className="sub_sp">{elem.name}</span>{" "}
                    {/* <span className="sub_sp1">(17)</span> */}
                  </div>
                );
              })
              : null}
          </div>
        </div>
      </section>
      <section className="filter_img mt-5">
        <div className="container">
          <div className="row">
            {allproduct
              ? allproduct.slice(0, productsToShow)?.map((elem) => {
                return (
                  <ProductCard
                    product_id={elem.product_id}
                    productimage={elem.image ? elem.image : elem.product_image}
                    productname={elem.product_name}
                    mrp={elem.mrp ? elem.mrp : elem.MRP}
                    price={elem.price}
                    t2_price={elem.t2_price}
                    t2_min={elem.t2_min}
                    stock={elem.stock}
                    addingToCartFunction={props.addingToCart}
                    isSlider="false"
                  />
                );
              })
              : null}
          </div>
          {allproduct?.length > 0 ?
            <div className="row">
              {allproduct?.length > 16 ? (
                <div className="col-12 col-md-12 text-center">
                  <button
                    className="btn"
                    id="showmoreproducts"
                    onClick={() => showMoreProducts()}
                    style={{ background: "rgb(43, 43, 43)", color: "white", boxShadow: 'none' }}
                  >
                    Show more
                  </button>
                </div>
              ) :
                null
              }
              <div className="col-12 col-md-12 text-center">{emptyMessage}</div>
            </div>
            : <div className="w-100 text-center">
              <h5>No Data Found!</h5>
            </div>}
        </div>
      </section>
    </div>
  );
}
export default Minorcategory;
