import React, { useEffect, useState } from "react";
import axios from "axios";
import CartProduct from "./CartProduct";
import { baseUrl } from "./baseUrl";
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import ModalHeader from 'react-bootstrap/esm/ModalHeader'
import { Link } from "react-router-dom";
function Cart(props) {
  // const [allcart, setcart] = useState([]);
  // console.log("this is cart props: ",props)
  const [token, setToken] = useState("");
  const [AllCart, setVD] = useState([]);
  const [num, setNum] = useState(0);
  var [cartLength, setCL] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [temp_product_id, settemp_product_id]=useState("")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const user_type = localStorage.getItem(
    "type"
  );
  const openModal=(product_id)=> {
    setIsModalOpen(true);
    settemp_product_id(product_id)
  }
  const incNum = () => {
    setNum(num + 1);
    alert("test");
  };
  const decNum = () => {
    setNum(num - 1);
    alert("test");
  };
  const getViewData = async () => {
    var getViewData = new FormData();
    getViewData.append("token_id", localStorage.getItem("user_token"));
    getViewData.append("phone", localStorage.getItem("supremetech_phone"));
    getViewData.append("authentication", localStorage.getItem("supremetech_authentication"));
    // for (let [key, value] of getViewData) {
    //   console.log("View Cart ka data", key, ":", value);
    // }
    try {
      const response = await axios.post(
        baseUrl+ "Apicontroller/view_cart_data",
        getViewData
      );
      console.log("Get Cart data:", response.data, baseUrl+ "Apicontroller/view_cart_data");
      if(response.data.status ===200){
        setVD('');
        setVD(response.data.data);
        // console.log("this is CartProdData",response.data.data)
        setSubtotal(response.data.subtotal);
      } else{
        setVD([])
      }
    } catch {
      console.log("Issue in getting cart data");
    }
  };
  useEffect(() => {
    async function gatCartLength() {
      try {
        const data = await props.countCartLengthFunction();
        console.log("Length", data);
        if (data !== undefined) {
          setCL(data);
        }
      } catch {
        console.log("Issue in getting cart length.");
      }
    }
    gatCartLength();
    getViewData();
  }, []);
  /*-------------------Delete Cart -----------------------*/
  // const [Delete , setDelete] = useState([]);
  const Deletedata = async (product_id) => {
    var Deletedata = new FormData();
    Deletedata.append("phone", localStorage.getItem("supremetech_phone"));
    Deletedata.append("authentication", localStorage.getItem("supremetech_authentication"));
    Deletedata.append("token_id", localStorage.getItem("user_token"));
    Deletedata.append("product_id", product_id);
    for (let [key, value] of Deletedata) {
      // console.log("Delete Cart ka data", key, ":", value);
    }
    try {
      const response = await axios.post(
        baseUrl+ "Apicontroller/delete_cart_data",
        Deletedata
      );
      // console.log("Delete Cart ka data:", response.data);
      if(response.data.status===200){
        props.changePopupText("Success! Product successfully removed.");
        let responseOfCount = props.countCartLengthFunction();
        // console.log("responseOfCount in delete", responseOfCount);
        getViewData();
        setIsModalOpen(false);
      }
      // setDelete(response.data.data);
    } catch {
      console.log("Issue in getting delete cart data");
    }
  };
  // Deletedata()
  /*-------------------Close Delete Cart -----------------------*/
  /*------------------------Update Data------------------------- */
  const UpdateCart = async (product_id, quantity, subtotal) => {
    var UpdateCart = new FormData();
    UpdateCart.append("token_id", localStorage.getItem("user_token"));
    UpdateCart.append("product_id", product_id);
    UpdateCart.append("quantity", quantity);
    UpdateCart.append("phone", localStorage.getItem("supremetech_phone"));
    UpdateCart.append("authentication", localStorage.getItem("supremetech_authentication"));
    for (let [key, value] of UpdateCart) {
      console.log("Update Data is:", key, ":", value);
    }
    try {
      const response = await axios.post(
        baseUrl+ "Apicontroller/update_cart",
        UpdateCart
      );
      console.log("  Update Data is inserted:", response.data);
      if (response.data.status === 200) {
        // alert(response.data.message);
        // props.changePopupText("Success! cart successfully updated.");
        getViewData();
      } else {
        props.changePopupText(response.data.message);
      }
      // setUpdate(response.data.data);
    } catch {
      console.log("Issue in update cart data");
    }
  };
  /*-------------------------close Update Data------------------- */
  return (
    <div>
      {/* login start */}
      <section className="mt-5 mb-5 pt-5 pb-5">
        <div className="container-fluid">
        <div className="row">
          <div class="col-md-12 text-center heading-div">
            <h1 className="heading" style={{marginBottom:"0px"}}>MY BASKET</h1>
            <img src="../img/bottom.png" style={{width:"13rem",marginTop:"-10px",marginBottom:"10px"}}></img>
          </div>
        </div>
        <div className="row">
        <Modal isOpen={isModalOpen} backdrop={true} style={{top:"40%"}}>
            <ModalHeader>
                Alert!
            </ModalHeader>
            <ModalBody>
                <p>Are you sure you want to remove product?</p>
            </ModalBody>
            <ModalFooter>
                <button className="btn" style={{background: '#2b2b2b', color: 'white'}} onClick={()=> Deletedata(temp_product_id)}>Yes</button>
                <button className="btn" style={{background: '#dc3545', color: 'white'}} onClick={()=> setIsModalOpen(false)}>No</button>
            </ModalFooter>
        </Modal>
        </div>
          <div className="row">
            {/* <div className="col-md-12 heading-div">
              <p>
                Your selection <b>( {cartLength} item )</b>
              </p>
            </div> */}
            <div className="col-md-9">
              <table className="cart_table">
                <tbody>
                  <tr
                    className="mb-4 mb-lg-0 text-center not_show"
                    style={{ background: "#edeee6" }}
                  >
                    <th className="pt-2 pb-2" />
                    <th className="pt-2 pb-2" />
                    <th className="pt-2 pb-2">PRODUCT</th>
                    <th className="pt-2 pb-2">PRICE</th>
                    {/* <th className="pt-2 pb-2">QUANTITY</th> */}
                    <th className="pt-2 pb-2">UPDATE</th>
                    <th className="pt-2 pb-2">TOTAL</th>
                  </tr>
                  {AllCart.length!==0
                    ? AllCart.map((elem) => {
                        return (
                          <CartProduct
                            UpdateCart={UpdateCart}
                            Deletedata={Deletedata}
                            AllCart={elem}
                            incNum={incNum}
                            decNum={decNum}
                            openModal={openModal}
                            user_type={user_type}
                            pop={props.changePopupText}
                          />
                        );
                      })
                    : (
                      <div
                        style={{ position: "absolute", width: '100%', textAlign: 'center' }}
                        className="emptyCart"
                      >
                        Your basket is empty.
                      </div>
                    )}
                </tbody>
              </table>{" "}
            </div>
           {AllCart.length!==0?( 
           <div className="col-md-3 mt-5 mt-lg-0" style={{zIndex:999}}>
              <table className="cart_table side_cart" style={{position:'sticky',top:'190px'}}>
                <tbody>
                  <tr style={{ background: "#edeee6" }}>
                    <th className="pt-2 pb-2 pl-2 pr-2">
                      Order Summary <span>({AllCart.length} item)</span>
                    </th>
                    <th />
                  </tr>
                  <tr>
                    <td>Subtotal</td>
                    <td>
                    <b>₹</b>
                      {subtotal}
                    </td>
                  </tr>
                  {/* <tr>
                    <td>VAT</td>
                    <td>
                      <span>$ </span>50.00
                    </td>
                  </tr>
                  <tr>
                    <td>Order Total</td>
                    <td>
                      <span>$ </span>400.00
                    </td>
                  </tr> */}
                  <tr>
                    <td className="bor_ra p-0" colSpan={2}>
                    <Link to={"/Order_checkout"}>
                        <button style={{backgroundColor:'#2b2b2b'}} className="cart_btn h-auto mob_btn">
                          <i className="fa fa-lock mr-3" aria-hidden="true" />
                          SECURE CHECKOUT
                        </button>
                      </Link>
                    </td>
                  </tr>
                    <label style={{ color: "#d21e25" }}>Freight Will Be Extra</label>
                </tbody>
              </table>
            </div>):null}
          </div>
        </div>
      </section>
    </div>
  );
}
export default Cart;
